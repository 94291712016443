import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/organisms/Layout/Layout';
import SEO from '../components/organisms/Seo/Seo';
import Catalog from '../pages/Catalog';

export const query = graphql`
  query CategoryTemplateQuery(
    $id: String!
    $categoryPath: String!
    $bigcommerce_id: Int!
    $skip: Int!
    $limit: Int!
  ) {
    category: allBigCommerceCategories(filter: { id: { eq: $id } }) {
      edges {
        node {
          description
          id
          bigcommerce_id
          image_url
          meta_description
          page_title
          name
        }
      }
    }

    categories: allBigCommerceCategories(filter: { is_visible: { eq: true } }) {
      edges {
        node {
          sort_order
          bigcommerce_id
          name
          image_url
          description
          parent_id
          custom_url {
            url
            is_customized
          }
        }
      }
    }

    brands: allBigCommerceBrands {
      edges {
        node {
          name
          id
          bigcommerce_id
        }
      }
    }

    products: allBigCommerceProducts(
      filter: { categories: { eq: $bigcommerce_id } }
      sort: { order: DESC, fields: date_created }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          entityId: bigcommerce_id
          name
          sku
          price
          calculated_price
          retail_price
          sale_price
          brand_id
          categories
          warranty
          custom_url {
            url
          }
          images {
            url_standard
            url_thumbnail
            url_zoom
            id
            description
            image_file
            is_thumbnail
            sort_order
          }
          variants {
            product_id
            id
            sku
          }
          custom_fields {
            name
            value
          }
        }
      }
    }

    productFilters: allBigCommerceProducts(
      filter: { categories: { eq: $bigcommerce_id } }
    ) {
      nodes {
        custom_fields {
          name
          value
        }
      }
    }

    seoContent: allBuilderModels {
      categorySeoContent(query: { data: { categoryUrl: $categoryPath } }) {
        data {
          content
        }
      }
    }
  }
`;

const CategoryTemplate = props => {
  const { data, errors, pageContext } = props;
  const categoryParent = 'retail';
  const category = data && data.category.edges[0].node;
  const categories = data && data.categories.edges?.map(a => a.node);
  // console.log(data, 'main data');
  const seoContent =
    data && data.seoContent.categorySeoContent.length > 0
      ? data.seoContent.categorySeoContent[0].data.content
      : null;

  const foundFilters = {
    Age: [],
    Design: [],
    Theme: [],
    Colour: [],
    Price: ['0$-$10', '$10-$30', '$30-$50', 'Over $50']
  };
  data.productFilters.nodes.map(node => {
    node.custom_fields.map(cf => {
      if (
        cf.name in foundFilters &&
        foundFilters[cf.name].indexOf(cf.value) === -1
      ) {
        foundFilters[cf.name].push(cf.value);
      }
      return true;
    });
    return true;
  });

  const products =
    data &&
    data.products.edges
      .map(product => {
        const brand =
          data &&
          data.brands.edges.find(
            brand => brand.node.bigcommerce_id === product.node.brand_id
          );
        product.node.brand_name = brand && brand.node.name;
        return product;
      })
      .filter(product => {
        // remove retail OOS products from search and PLP
        const isRetail =
          product.node.custom_fields.filter(field => field.name === 'PackQty')
            .length === 0;
        if (
          isRetail &&
          product.node.inventory_tracking !== 'none' &&
          product.node.inventory_level === 0
        ) {
          return false;
        }

        return true;
      });
  // console.log(foundFilters, 'foundFilters');
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {category && (
        <SEO
          title={category.page_title || category.name || 'Untitled'}
          description={category.meta_description || category.description}
        />
      )}

      {/* {errors && (
            <Container>
              <GraphQLErrorList errors={errors} />
            </Container>
        )} */}

      {category && (
        <Catalog
          pageContext={pageContext}
          category={category}
          categoryDisplay={categoryParent}
          products={products}
          categories={categories}
          foundFilters={foundFilters}
          seoContent={seoContent}
        />
      )}
    </Layout>
  );
};

export default CategoryTemplate;
